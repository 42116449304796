.bg-content-cus {
    background-image: url("../media/auth/bg1-dark.jpg");
}

.pagination.react-bootstrap-table-page-btns-ul {
    justify-content: flex-end;
}

.search-label {
    margin-bottom: 1.25rem;
    float: inline-end !important;
}

.float-inline-end {
    float: inline-end !important;
}

.react-bootstrap-table table {
    table-layout: inherit !important;
}

.table-responsive {
    width: 100%;
}

.al-thumb {
    border: 1px solid #eaeaea;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-flex;
    height: 100px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 4px;
    width: 100px;
}

.btn-delete-image {
    background: none;
    border: none;
    color: red;
    font-size: 14px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    text-align: center;
    right: 0px;
    top: 4px;
}

.al-img-table {
    width: 50px;
}

.al-cursor-pointer {
    cursor: pointer;
}

.zi-cs .filter-label {
    display: block !important;
}

.al-menu-lang {
    position: fixed;
    top: 57.5%;
    right: 0;
}

.al-custom-select-search-variants {
    display: inline-block !important;
    width: auto !important;
}

.al-filter-table .css-qbdosj-Input {
    line-height: inherit !important;
}

.dropdown-item a {
    display: block;
}

.al-variants-table .react-bootstrap-table {
    max-height: 500px;
    overflow-y: scroll;
}

.al-disabled {
    pointer-events: none;
    opacity: 0.4;
}

.al-select {
    z-index: 9;
    min-width: 150px;
}

.al-select .select__control {
    height: 100%;
}

.w-max-content {
    width: max-content !important;
}

.cotik-select-virtual > div {
    min-width: 200px;
}

.al-variants-table.al-max-height-table-cus .react-bootstrap-table {
    max-height: calc(100vh - 130px) !important;
}

.al-input-basecost-order-cus {
    height: 40px;
    margin-top: 3px;
    border-radius: 3px 0px 0px 3px;
}

.al-input-group-basecost-order-cus {
    height: 40px;
    margin-top: 3px;
    border-radius: 0px 3px 3px 0px;
}

.al-d-none-option-react-select .select__option--is-disabled {
    display: none !important;
}

.select__single-value.css-1dimb5e-singleValue > .option.cursor-pointer > .row {
    display: none !important;
}

.al-emo-css {
    position: absolute !important;
    bottom: 70px !important;
    left: 65px !important;
    z-index: 1002 !important;
}

#my-tooltip {
    z-index: 3;
}

/* .al-table-order-sticky tr > td:nth-child(1),
.al-table-order-sticky tr:nth-child(1) > th:nth-child(1) {
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    left: -1px;
    z-index: 2;
}

.al-table-order-sticky tr > td:nth-child(2),
.al-table-order-sticky tr:nth-child(1) > th:nth-child(2) {
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    left: 32px;
    z-index: 2;
}

.al-table-order-sticky tr > td:nth-child(3),
.al-table-order-sticky tr:nth-child(1) > th:nth-child(3) {
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    left: 99px;
    z-index: 2;
}

.al-table-order-sticky tr > td:nth-child(4),
.al-table-order-sticky tr:nth-child(1) > th:nth-child(4) {
    background-color: #fff;
    position: sticky;
    position: -webkit-sticky;
    left: 194px;
    z-index: 2;
}

.select__menu {
    z-index: 3 !important;
} */

.react-bootstrap-table-editing-cell {
    min-width: 200px !important
}

/* @media (min-width: 992px) {
    .react-bootstrap-table.table-responsive {
        position: relative;
        overflow: visible;
    }
} */

/* .zxczxc {
    position: fixed !important;
    top: auto !important;
    left: auto !important;
} */

.react-bootstrap-table.table-responsive {
    min-height: 355px;
}

.react-bootstrap-table.table-responsive tr:nth-last-child(2) .al-zoom-img-cus {
    top: -155px !important;
}

.react-bootstrap-table.table-responsive tr:last-child .al-zoom-img-cus {
    top: -245px !important;
}

.al-form-control-daterangepicker {
    width: auto !important;
    display: inline !important;
    height: 38px !important;
    vertical-align: -1px;
}

.al-custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.al-custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.al-custom-file-input:focus ~ .al-custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.al-custom-file-input[disabled] ~ .al-custom-file-label,
.al-custom-file-input:disabled ~ .al-custom-file-label {
    background-color: #e9ecef;
}

.al-custom-file-input:lang(en) ~ .al-custom-file-label::after {
    content: "Browse";
}

.al-custom-file-input ~ .al-custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.al-custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.al-custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.centered-cell {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    height: 100% !important;
}

.ag-center-cols-container > .ag-row:hover {
    z-index: 1;
}

.ag-grid-paginate-custom {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: var(--ag-secondary-foreground-color);
    height: var(--ag-header-height);
    border: 1px solid;
    border-color: var(--ag-border-color);
    color: var(--ag-secondary-foreground-color);
    height: var(--ag-header-height);
}

.ag-grid-paginate-custom ul {
    margin: 0;
    padding: 0;
}

.ag-grid-paginate-custom li {
    float: left;
    list-style: none;
    font-size: 16px;
    margin: 0 5px;
}

.ag-grid-paginate-custom li.selected {
    font-weight: bolder;
}

.ag-grid-paginate-custom .disabled {
    opacity: .3;
}

.al-cell-auto-w-table .ag-cell-wrapper {
    width: auto;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

.mostly-customized-scrollbar {
    scrollbar-width: unset !important;
    scrollbar-color: auto !important;
}
  
.ag-header-cell {
    background-color: #fff;
}

.ag-root-wrapper {
    border: #F1F1F4 solid 1px !important;
}

.ag-header, .ag-advanced-filter-header {
    border-bottom: #F1F1F4 solid 1px !important;
}

.ag-grid-paginate-custom {
    border: #F1F1F4 solid 1px !important;
}

.ag-theme-alpine {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    font-family: Inter, Helvetica, sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    height: 100%;
    margin: 0 !important;
}

.ag-row-even {
    background-color: #fbfbfb !important;
}

.ag-row-odd {
    background-color: #fff !important;
}

.al-cus-h-ag-table {
    /* height: calc(100vh - 65px) !important; */
    height: 100vh !important;
}

.ag-header-container {
    background: #fff;
}

.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
}

.customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
}

.customSortUpLabel {
    float: left;
    margin: 0;
}

.customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
}

.al-zoom {
    zoom: 70%;
}

.overflow-visible .ag-cell-value {
    overflow: visible;
}

.al-h-cus-tb-order .al-cus-h-ag-table {
    /* height: calc(100vh - 65px) !important; */
    height: 120vh !important;
}

.rsm-geography {
    outline: none !important;
}

.menu-link.active .al-nav-svg {
    filter: brightness(0) invert(1);
}

.menu-link:hover .al-nav-svg {
    filter: brightness(0) invert(1);
}